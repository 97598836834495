export const environment = {
    production: true,
    protocol: 'https',
    host_sk: 'api.myphoenix.sk',
    host_cz: 'api.myphoenix.cz',
    basicAuthToken: 'cGhvZW5peDpYYkg5QUZRODlOd1gzQTNz',
    debug: false,
    pdf_version_sk: 'V-01-2021',
    pdf_version_cz: 'V-01-2022',
};
